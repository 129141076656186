<template>
  <div class="page">
    <div class="page-header">
      <a-form-model
        class="ant-advanced-search-form"
        :labelCol="{span: 6}"
        :wrapperCol="{span: 18}"
        :form="form" @submit="handleSearch">
        <a-row :gutter="24">
          <a-col
            :span="8"
          >
            <a-form-model-item label="手机号" prop="mobileNo">
              <a-input v-model="form.mobileNo" :maxLength="11" autocomplete="off" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" :offset="2" of :style="{ textAlign: 'left' }">
            <a-button type="primary" html-type="submit">
              搜索
            </a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <a-row class="page-content">
      <a-col :span="24">
        <a-table
          :scroll="{ x: 2500 }"
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :row-key="record => record.repayNo"
          @change="handlePageChange"
        >
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { repayOrderListQuery } from '@/api/repay';

const columns = [
  {
    title: '订单号',
    dataIndex: 'orderNo',
    key: 'orderNo',
  },
  {
    title: '还款号',
    dataIndex: 'repayNo',
    key: 'repayNo',
  },
  {
    title: '渠道方编号',
    dataIndex: 'channelCode',
    key: 'channelCode',
  },
  {
    title: '渠道方还款号',
    dataIndex: 'channelRepayNo',
    key: 'channelRepayNo',
  },
  {
    title: '还款状态',
    dataIndex: 'repayStatus',
    key: 'repayStatus',
  },
  {
    title: '失败原因',
    key: 'failReason',
    dataIndex: 'failReason',
    ellipisis: true,
  },
  {
    title: '资金方编码',
    key: 'fundCode',
    dataIndex: 'fundCode',
  },
  {
    title: '资金方还款号',
    key: 'fundRepayNo',
    dataIndex: 'fundRepayNo',
  },
  {
    title: '还款时间',
    key: 'repayDate',
    dataIndex: 'repayDate',
  },
  {
    title: '手机号',
    key: 'mobileNo',
    dataIndex: 'mobileNo',
  },
  {
    title: '身份证号',
    key: 'idNo',
    dataIndex: 'idNo',
  },
  {
    title: '姓名',
    key: 'name',
    dataIndex: 'name',
    width: 100,
  },
  {
    title: '还款金额',
    key: 'repayTotalAmount',
    dataIndex: 'repayTotalAmount',
  },
  {
    title: '开始期数',
    key: 'periodStart',
    dataIndex: 'periodStart',
  },
  {
    title: '结束期数',
    key: 'periodEnd',
    dataIndex: 'periodEnd',
  },
];

export default {
  name: 'Home',
  data() {
    return {
      data: [],
      columns,
      expand: false,
      form: {
        mobileNo: '',
      },
      pagination: {
        current: 1,
        total: 0,
      },
    };
  },
  methods: {
    handleSearch(e) {
      e.preventDefault();
      this.fetchPage();
    },
    handlePageChange(current) {
      this.pagination.current = current.current;
      this.fetchPage();
    },
    async fetchPage() {
      this.loading = true;
      const { result, success, errorMsg } = await repayOrderListQuery({
        ...this.form,
        limit: 10,
        offset: this.pagination.current,
      });
      this.loading = false;
      if (!success) {
        this.$message.error(errorMsg);
        return;
      }
      this.data = result.repayOrderFlowList;
      this.pagination.total = result.total;
    },
  },
  mounted() {
    this.fetchPage();
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 30px 20px;
}

.page-content {
  background-color: #fff;
  padding: 16px 12px 0;
}
</style>
